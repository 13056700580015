
    import { mapGetters } from 'vuex';

    import utilities from '@/mixins/utilities';
    import createCustomer from '@/components/MhCustomers/createCustomer.vue';

    import Vue from 'vue';
    export default Vue.extend({
        name: 'CustomerList',
        components: { createCustomer },
        mixins: [utilities],
        data() {
            return {
                search: '',
                headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        value: 'name'
                    },
                    { text: 'Account Manager', value: 'am_name' },
                    { text: 'Product', value: 'product' },
                    { text: 'Usage Metric', value: 'usage_metric' },
                    { text: 'License Usage', value: 'total_usage_for_term' },
                    { text: 'License Expiration', value: 'expiration_date' }
                ]
            };
        },
        computed: {
            ...mapGetters({
                customers: 'customers/allCustomers'
            })
        },
        created() {
            this.$store.dispatch('customers/getCustomersAndAMs');
        },
        methods: {
            getLicenseProgress(start_date: string, expiration_date: string) {
                const now = new Date().getTime();
                const startDate = new Date(start_date).getTime();
                const expirationDate = new Date(expiration_date).getTime();

                if (now < startDate) return 0;
                if (now > expirationDate) return 100;

                const totalDuration = expirationDate - startDate;
                const elapsed = now - startDate;
                return Math.min((elapsed / totalDuration) * 100, 100);
            },
            getUsageProgress(usageForTerm: number, usageLimit: number) {
                if (!usageLimit || usageLimit <= 0) return 0;
                const progress = (usageForTerm / usageLimit) * 100;
                return Math.min(progress, 100);
            },
            determineColor(percentComplete: number) {
                if (percentComplete >= 90) return 'red';
                if (percentComplete >= 75) return 'orange';
                return 'green';
            },
            handleClick(value: { id: string }): void {
                this.$router.push('/customer/' + value.id);
            },
            formatProductName(product: string) {
                if (product == 'contentcontroller') {
                    return 'Content Controller';
                } else {
                    return utilities.methods.capitalizeFirstLetter(product);
                }
            }
        }
    });
